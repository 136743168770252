import Vue from 'vue';
import Vuex from 'vuex';
import { signInAnonymously, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth';
import { APIClient } from '../services/api/api';
import { auth, initAnalytics, sendEvent } from '../services/firebase';
import { getSearchParam } from '../utils/search-params';

Vue.use(Vuex);

const gameModes = [
  {
    id: 'standard',
    title: 'Bad Cards™',
    icon: '😈',
    description: 'Reply with your funniest response card and show your bad side.',
  },
  {
    id: 'questions',
    title: 'Bad Questions™',
    icon: '❓',
    description: 'We give you the answer - it\'s up to you to play the question!',
  },
  {
    id: 'texts',
    title: 'Bad Texts™',
    icon: '📱',
    description: 'Someone sent a text message to the wrong number - Send back your best response!',
  },
  {
    id: 'lyrics',
    title: 'Meme the Music™',
    icon: '🎤',
    description: 'Match lyrics and images to create hilarious memes. Taylor Swift, Drake, Kanye, and more!',
  },
  {
    id: 'free-form',
    title: 'Dafuq™',
    icon: '🤔',
    description: 'Dafuq do you think of the judge this round? With every question played, dish it out.',
  },
  {
    id: 'gifs',
    title: 'Bad GIFs™',
    icon: '🙃',
    description: 'What do you wish the gif said? Now\'s your chance.',
  },
  {
    id: 'memes',
    title: 'Bad Memes™',
    icon: '🤡',
    description: 'Fill-in-the-blank to make hilarious memes out of outrageous pictures.',
  },
  {
    id: 'bubbles',
    title: 'Bad Bubbles™',
    icon: '💬',
    description: 'Funny pictures and even funnier captions. Fun for kids of all ages.',
  },
  {
    id: 'girls',
    title: 'Bad Girls™',
    icon: '💁‍♀️',
    description: 'The hilarious (and naughty) party game for a Girls Night.',
  },
  {
    id: 'kids',
    title: 'Bad Kids™',
    icon: '🧒',
    description: 'A family-friendly party game for kids of all ages! Reply with your funniest response and show your silly side.',
  },
  {
    id: '3words',
    title: '3 Words™',
    icon: '③',
    description: '3 Words to say it all. Write 3 words to respond to each card.',
  },
  {
    id: 'downtown',
    title: 'Downtown Dictionary™',
    icon: '📖',
    description: 'What is The Frozen Tortoise? The Dutch Tickle? Fill-in-the-blank with your hilarious definition.',
  },
  {
    id: 'blanks',
    title: 'Bad Blanks™',
    icon: '🫥',
    description: 'The Bad Cards game you love, but you fill in the blanks with your own terrible responses.',
  },
];

const store = new Vuex.Store({
  state: {
    user: null,
    client: null,
    lang: null,
    strings: null,
    currencies: {
      eur: '€',
      gbp: '£',
      usd: '$',
    },
    languages: [
      { code: 'EN', label: 'English' },
    ],
    gameModes,
    gameModeNames: gameModes.reduce((list, mode) => {
      list[mode.id] = mode.title;
      return list;
    }, {}),
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setClient(state, client) {
      state.client = client;
    },
    setStrings(state, strings) {
      state.strings = strings;
    },
    setLang(state, lang) {
      state.lang = lang;
      window.localStorage.setItem('lang', lang);
    },
  },
  actions: {
    openAuth(context, form) {
      window.dispatchEvent(new CustomEvent('open-auth', { detail: { form } }));
      sendEvent('auth-opened');
    },
    openGame() {
      window.dispatchEvent(new CustomEvent('open-game'));
      sendEvent('game-opened');
    },
    openDeck(context, detail) {
      window.dispatchEvent(new CustomEvent('open-deck', { detail }));
      sendEvent('deck-opened', { deck_id: detail.deck?.id });
    },
    onFavoriteChange(context, detail) {
      window.dispatchEvent(new CustomEvent('favorite-change', { detail }));
    },
    showMessage(context, detail) {
      window.dispatchEvent(new CustomEvent('open-alert', { detail }));
    },
  },
});

Vue.mixin({
  methods: {
    t(value) {
      const { strings } = store.state;
      if (!strings || !strings[value]) {
        return value;
      }
      return strings[value];
    },
  },
});

let token = getSearchParam('token', true);

onAuthStateChanged(auth, async (authState) => {
  try {
    let authUser;
    if (token) {
      const session = await signInWithCustomToken(auth, token);
      token = null;
      authUser = session.user;
    } else if (authState) {
      authUser = authState;
    } else {
      const session = await signInAnonymously(auth);
      authUser = session.user;
    }
    // eslint-disable-next-line eqeqeq
    if (window.localStorage.getItem('allow-cookies') == 'true') {
      initAnalytics(authUser.uid);
    }
    const client = new APIClient(authUser);
    store.commit('setClient', client);
    const user = await client.get('/account');
    store.commit('setUser', user);
  } catch (error) {
    token = null;
    console.warn(error.message);
  }
});

export default store;
