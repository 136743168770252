<template>
  <form id="stripe-form" :class="loading || disabled ? 'disabled' : ''" @submit.prevent="submitPayment">
    <div class="payment-methods" v-if="paymentMethods.length">
      <div class="payment-methods-title">Your payment methods</div>
      <div class="list">
        <div class="payment-method" v-for="paymentMethod in paymentMethods" :key="paymentMethod.id">
          <CheckBox
            size="l"
            :disabled="paymentMethod.card.exp_year < date.getFullYear() || (paymentMethod.card.exp_year === date.getFullYear() && paymentMethod.card.exp_month < date.getMonth() + 1)"
            :model-value="selectPaymentMethod === paymentMethod.id"
            @change="selectPaymentMethod = $event ? paymentMethod.id : ''"
          />
          <div class="payment-method-content">
            <div class="payment-method-title"><span class="brand">{{ paymentMethod.card.brand }}</span> ending with {{ paymentMethod.card.last4 }}</div>
            <div class="payment-method-info">Expires on {{ paymentMethod.card.exp_month }}/{{ paymentMethod.card.exp_year }}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="card-element" :style="{ display: selectPaymentMethod ? 'none' : 'block' }"></div>
    <!-- <div class="field">
      <label for="discount">Discount code</label>
      <input id="discount" name="discount" placeholder="Enter a discount code..." v-model="discountCodeValue" type="text" class="input" maxlength="48" @input="validateDiscountCode">
      <div class="loader" v-if="loadingDiscount"></div>
    </div> -->
    <div class="success-message" v-if="discountCode"><b>Discount:</b> {{ discountCode.description }}</div>
    <button type="submit" class="btn orange" :disabled="loading || disabled">
      {{ buttonText || 'Pay' }}
      <span v-if="product">{{ currencies[product.currency] + product.price }}</span>
    </button>
    <div class="tos">{{ t('By clicking Pay, you agree to our Returns & Refunds, Terms of Service, and Privacy Policy. You also agree to the Link Terms and Privacy Policy.', lang) }}</div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { showError } from '../utils/error-handling';
import { IS_PROD } from '../services/api/api';

const stripe = window.Stripe(IS_PROD ? 'pk_live_51I4900Cx2mbkgCdAb3ZRsm7qermrjlPt6lMErhdgKLoEmcu4LKbHEXJsdWUQ5RasGXw2Ivgjt1YFlSjdABCXMrgU00xGEUnbVJ' : 'pk_test_51QCKr1CTYEl8TAYzZvLOdkQTAhog7iEmV0uZ8QtONqswfeQ0i0HkXjhT7DKX5Zl5bh44W0maIcmeqhgkR59YxTzo00DGAyZwJz');

export default {
  name: 'StripeForm',
  computed: {
    ...mapState(['client', 'user', 'currencies']),
  },
  props: {
    paymentSecret: String,
    buttonText: String,
    disabled: Boolean,
    item: String,
    product: Object,
  },
  data() {
    return {
      cardElement: null,
      paymentMethods: [],
      selectPaymentMethod: '',
      discountCode: null,
      discountCodeValue: '',
      loading: false,
      loadingDiscount: false,
      date: new Date(),
    };
  },
  methods: {
    async submitPayment() {
      if (this.loading) {
        return;
      }
      if (this.paymentSecret) {
        this.loading = true;
        const { error } = await stripe.confirmPayment({
          elements: this.elements,
          confirmParams: {
            return_url: `${window.location.origin}/?purchased_item=${this.item}`,
          },
          redirect: 'if_required',
        });
        if (!error) {
          this.$emit('payment-success');
        } else if (error.type === 'card_error' || error.type === 'validation_error') {
          showError(error);
        } else {
          showError('An unexpected error occurred.');
        }
        this.loading = false;
        return;
      }
      if (this.selectPaymentMethod) {
        this.$emit('payment-method', {
          paymentMethod: this.selectPaymentMethod,
          discountCode: this.discountCodeValue,
        });
        return;
      }
      this.loading = true;
      stripe.createPaymentMethod('card', this.cardElement).then((result) => {
        if (result.error) {
          showError(result.error);
        } else {
          this.$emit('payment-method', {
            paymentMethod: result.paymentMethod.id,
            discountCode: this.discountCodeValue,
          });
        }
        this.loading = false;
      });
    },
    async getPaymentMethods() {
      this.paymentMethods = await this.client.get('/account/payment-methods');
    },
    async validateDiscountCode() {
      this.discountCode = null;
      this.loadingDiscount = false;
      clearTimeout(this.discountTO);
      this.discountTO = setTimeout(async () => {
        try {
          this.loadingDiscount = true;
          const result = await this.client.post('/discount-codes/validate', {
            code: this.discountCodeValue,
          });
          if (result.code === this.discountCodeValue) {
            this.discountCode = result;
          }
        } catch (err) {
          showError(err);
        }
        this.loadingDiscount = false;
      }, 1000);
    },
    async initForm() {
      if (this.paymentSecret) {
        this.elements = stripe.elements({ clientSecret: this.paymentSecret });
        this.cardElement = this.elements.create('payment', {
          layout: 'tabs',
          hidePostalCode: true,
        });
      } else {
        this.elements = stripe.elements();
        this.cardElement = this.elements.create('card', {
          hidePostalCode: true,
        });
      }
      this.cardElement.mount('#card-element');
    },
  },
  mounted() {
    this.initForm();
  },
  beforeDestroy() {
    clearTimeout(this.discountTO);
  },
};
</script>

<style scoped>
#card-element {
  padding: 12px 16px;
  background: #fff;
  margin: 16px 0;
  border-radius: 8px;
}

#card-element.disabled {
  opacity: 0.33;
  pointer-events: none;
}

.btn {
  width: 100%;
}

.powered-by {
  width: 152px;
  margin: 24px auto 0;
}

.payment-methods {
  margin-bottom: 24px;
}

.payment-methods-title {
  font-weight: 500;
  margin-bottom: 12px;
}

.payment-method {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
}

.payment-method-info {
  opacity: 0.69;
  font-size: 0.875rem;
  margin-top: 4px;
}

.error,
.success-message {
  margin-bottom: 16px;
}

.success-message {
  background: var(--text-highlight-color);
}

.field {
  position: relative;
}

.field .loader {
  position: absolute;
  bottom: 4px;
  right: 0;
}

.tos {
  text-align: center;
  opacity: 0.75;
  margin-top: 12px;
  font-size: 12px;
}
</style>
