export const IS_PROD = window.location.host.includes('bad.cards');
const API_DOMAIN = IS_PROD ? 'bad.cards' : 'bad-cards-39942.web.app';

export class APIClient {
  baseUrl = `https://${API_DOMAIN}/api/v1`;

  user = null;

  constructor(user) {
    this.user = user;
  }

  async request(path, options = {}) {
    const token = await this.user.getIdToken();
    const response = await fetch(`${this.baseUrl}${path}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      ...options,
    });
    if (response.ok) {
      return response.json();
    }
    const error = await response.json();
    throw error;
  }

  get(path, params = {}) {
    const qs = new URLSearchParams(params).toString();
    return this.request(`${path}${qs ? `?${qs}` : ''}`, {
      method: 'GET',
    });
  }

  post(path, body) {
    return this.request(path, {
      method: 'POST',
      body: body && JSON.stringify(body),
    });
  }

  delete(path) {
    return this.request(path, {
      method: 'DELETE',
    });
  }
}
