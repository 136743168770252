import { render, staticRenderFns } from "./RecentGames.vue?vue&type=template&id=5841f933&scoped=true&"
import script from "./RecentGames.vue?vue&type=script&lang=js&"
export * from "./RecentGames.vue?vue&type=script&lang=js&"
import style0 from "./RecentGames.vue?vue&type=style&index=0&id=5841f933&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5841f933",
  null
  
)

export default component.exports