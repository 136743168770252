import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { ref, uploadBytes, getStorage } from 'firebase/storage';
import { IS_PROD } from '../api/api';

const firebaseConfig = {
  apiKey: 'AIzaSyAuSl0o40RQ8uPtXVBBTUxSqIj4pGNXLhE',
  authDomain: 'bad-cards-39942.firebaseapp.com',
  projectId: 'bad-cards-39942',
  storageBucket: 'bad-cards-39942.appspot.com',
  messagingSenderId: '1050451935595',
  appId: '1:1050451935595:web:3a4eb8841fa9709801cc2e',
  measurementId: 'G-7DRP61QQ6C',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const firestore = getFirestore(app);

export const storage = getStorage(app);

let analytics;

export const sendEvent = (event, data) => analytics && logEvent(analytics, event, data);

export const initAnalytics = (userId) => {
  if (!IS_PROD) {
    return;
  }
  if (!analytics) {
    analytics = getAnalytics(app);
  }
  setUserId(analytics, userId);
  sendEvent('pageview', {
    path: window.location.pathname,
    lang: window.localStorage.getItem('lang') || 'EN',
  });
};

export const uploadFile = async (path, file, fileName) => {
  const name = file.name.split('.');
  const ext = name.pop();
  const fullName = `${fileName || name.join('.')}.${ext}`;
  const fullPath = `${path}/${fullName}`;
  const fileRef = ref(storage, fullPath);
  await uploadBytes(fileRef, file);
  return {
    key: name.join('.'),
    ext,
    file: fullName,
    path: fullPath,
  };
};
