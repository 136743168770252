export const showError = (error) => {
  if (!window.location.origin.includes('bad.cards')) {
    console.warn(error);
  }
  window.dispatchEvent(new CustomEvent('open-alert', {
    detail: {
      type: 'error',
      message: error.message,
    },
  }));
};
