<template>
  <div class="dropdown-container" v-if="items.length">
    <button ref="dropdownButton" :class="`dropdown-button btn ${buttonClass}`" @click="toggleMenu">
      <slot></slot>
    </button>
    <div class="dropdown-menu" :style="{ width }" v-if="opened">
      <a class="dropdown-item" v-for="item in items" :key="item.value" :selected="item.value === selected" @click="$emit('change', item)">{{ item.label }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownButton',
  props: {
    width: {
      type: String,
      default: '220px',
    },
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: String,
    },
    buttonClass: {
      String,
      default: 'small ghost',
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    toggleMenu() {
      this.opened = !this.opened;
    },
    closeMenu(e) {
      if (e.target === this.$refs.dropdownButton) {
        return;
      }
      this.opened = false;
    },
  },
  mounted() {
    window.addEventListener('click', this.closeMenu);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeMenu);
  },
};
</script>

<style scoped>
.dropdown-container {
  position: relative;
}

.dropdown-container .dropdown-menu {
  position: absolute;
  width: 100%;
  top: 100%;
  right: 0;
  margin-top: 8px;
  border-radius: 8px;
  background: #021890;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.dropdown-container .dropdown-menu .dropdown-item {
  display: block;
  padding: 8px 12px;
  text-align: center;
}

.dropdown-container .dropdown-menu .dropdown-item:hover {
  background: rgba(255, 255, 255, 0.16);
}

.dropdown-container .dropdown-menu .dropdown-item[selected] {
  background: rgba(0, 0, 0, 0.48);
  color: #e53d13;
}

.dropdown-container .dropdown-button > * {
  pointer-events: none;
}
</style>
