<template>
  <div class="deck-item" :hide-card="hideCard" v-if="deckData">
    <CardItem v-if="!hideCard" :card="previewCard" :cover="deck.cover" hover-flip :mode="deckData.type === 'custom' ? 'custom' : deckData.game_modes[0]" />
    <div class="deck-info">
      <div class="deck-title">{{ deckData.title }}</div>
      <div class="deck-author">by <b>{{ deckData.user.name || t('Unknown', lang) }}</b></div>
      <div class="deck-cards" v-if="usePreview"><b>{{ deckData.card_ids.length }}</b> {{ t('cards', lang) }}</div>
      <div v-if="!usePreview" class="deck-tags">
        <div class="tag language">{{ deckData.language || 'English' }}</div>
        <div class="tag mode" v-for="mode in deckData.game_modes" :key="mode">{{ gameModeNames[mode] || mode }}</div>
      </div>
      <div v-if="!hideActions" class="deck-actions">
        <button class="btn secondary small" @click="openDetails(false)" :disabled="loading">ℹ</button>
        <button v-if="!usePreview" class="btn secondary small" @click="toggleFavorite(!deckData.favorite)" :disabled="loading">{{ deckData.favorite ? '💔' : '❤️' }}</button>
        <button v-if="deckData.type === 'paid' && !isAvailable" class="btn small highlight" @click="openDetails(true)" :disabled="loading">{{ t('Get', lang) }}</button>
        <button v-else-if="useSelect" :class="`btn small${selected ? ' plain' : ''}`" @click="$emit('select', deckData)" :disabled="(!selected && disabled) || loading">{{ t(selected ? 'Remove' : 'Add', lang) }}</button>
        <button v-else-if="!usePreview" class="btn small" @click="createGame(deckData.game_modes[0], deckData)" :disabled="!deckData.card_ids.length || loading">{{ t('Play', lang) }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  collection, getCountFromServer, query, where,
} from 'firebase/firestore';
import { firestore, sendEvent } from '../services/firebase';
import CardItem from './CardItem.vue';

export default {
  name: 'DeckItem',
  components: {
    CardItem,
  },
  data() {
    return {
      loading: false,
      deckData: null,
      purchased: null,
    };
  },
  computed: {
    ...mapState(['user', 'client', 'lang', 'gameModeNames']),
    isAnonymous() {
      return this.user.type === 'anonymous';
    },
    previewCard() {
      if (!this.deckData) {
        return null;
      }
      const cards = Object.values(this.deckData.cards);
      const prompt = cards.find((card) => card.card.type === 'prompt');
      if (prompt) {
        return prompt.card;
      }
      return cards[0]?.card || null;
    },
    isAvailable() {
      if (!this.deckData) {
        return false;
      }
      return this.deckData.type !== 'paid' || this.purchased || (this.deckData.subscriptions && this.deckData.subscriptions.includes(this.user.subscription_type));
    },
  },
  props: {
    deck: {
      type: Object,
    },
    defaultOpen: {
      type: Boolean,
    },
    hideActions: {
      type: Boolean,
    },
    hideCard: {
      type: Boolean,
    },
    useSelect: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    selected: {
      type: Boolean,
    },
    usePreview: {
      type: Boolean,
    },
  },
  watch: {
    deck() {
      this.deckData = { ...this.deck };
    },
  },
  methods: {
    ...mapActions(['openAuth', 'openDeck', 'showMessage', 'onFavoriteChange']),
    openDetails(openPayment) {
      if (openPayment && this.isAnonymous) {
        this.openAuth();
        return;
      }
      this.openDeck({
        deck: this.deckData,
        purchased: this.purchased,
        openPayment,
        preview: this.usePreview,
      });
    },
    openSubscription() {
      window.dispatchEvent(new CustomEvent('open-subscription'));
    },
    async getDetails(openDetails) {
      if (this.deckData.type === 'paid' && !this.deckData.subscriptions) {
        this.loading = true;
        const transactions = await getCountFromServer(
          query(
            collection(firestore, 'transactions'),
            where('user_id', '==', this.user.id),
            where('item_id', '==', this.deckData.id),
          ),
        );
        this.purchased = transactions.data().count > 0;
        this.loading = false;
        if (openDetails) {
          this.openDetails();
        }
      }
    },
    async createGame(mode, deck) {
      if (this.isAnonymous) {
        this.openAuth();
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const settings = {
          name: `${this.user.name || 'Unknown'}'s game`,
          mode,
          decks: deck ? [deck.id] : null,
          is_manual: true,
        };
        const game = await this.client.post('/games', settings);
        settings.id = game.id;
        sendEvent('create-game', settings);
        this.$router.push(`/g/${game.id}`);
      } catch (error) {
        console.warn(error);
      }
      this.loading = false;
    },
    async toggleFavorite(favorite) {
      if (this.isAnonymous) {
        this.openAuth();
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        if (favorite) {
          await this.client.post(`/decks/${this.deckData.id}/favorites`);
          sendEvent('favorite-deck', { deck_id: this.deckData.id });
        } else {
          await this.client.delete(`/decks/${this.deckData.id}/favorites`);
          sendEvent('unfavorite-deck', { deck_id: this.deckData.id });
        }
        this.onFavoriteChange({
          deckId: this.deckData.id,
          favorite,
        });
      } catch (error) {
        console.warn(error);
      }
      this.loading = false;
    },
    handleFavoriteChange(event) {
      const { deckId, favorite } = event.detail;
      if (this.deckData?.id === deckId) {
        this.deckData = {
          ...this.deckData,
          favorite,
        };
      }
    },
    handleDeckPurchased(event) {
      const { deckId } = event.detail;
      if (this.deckData?.id === deckId) {
        this.purchased = true;
      }
    },
  },
  mounted() {
    this.deckData = this.deck || null;
    this.getDetails(this.defaultOpen);
    window.addEventListener('favorite-change', this.handleFavoriteChange);
    window.addEventListener('deck-purchased', this.handleDeckPurchased);
  },
  beforeDestroy() {
    window.removeEventListener('favorite-change', this.handleFavoriteChange);
    window.removeEventListener('deck-purchased', this.handleDeckPurchased);
  },
};
</script>

<style scoped>
.deck-item {
  position: relative;
  text-align: left;
}
.deck-info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 64px 16px 16px;
  background: #5f51d7;
  background: linear-gradient(0deg, rgba(95,81,215,0.9) 50%, rgba(95,81,215,0) 100%);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  z-index: 1;
  will-change: transform;
  pointer-events: none;
}
.deck-item[hide-card] .deck-info {
  position: relative;
  background: rgb(95, 81, 215);
  border-radius: 16px;
  padding: 8px 12px;
}
.deck-title {
  font-weight: bold;
}
.deck-item[hide-card] .deck-title {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.deck-cards,
.deck-author {
  font-size: 14px;
  margin-bottom: 12px;
  color: rgba(255, 255, 255, 0.64);
}
.deck-cards,
.deck-item[hide-card] .deck-author {
  margin-bottom: 0;
}
.deck-tags {
  display: flex;
  gap: 4px 8px;
  flex-wrap: wrap;
}
.deck-tags .tag {
  font-size: 12px;
  background: #5f51d7;
  padding: 2px 6px;
  border-radius: 12px;
  text-transform: capitalize;
}
.deck-description {
  font-size: 14px;
}
.deck-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  gap: 4px;
  pointer-events: initial;
}
.deck-item[hide-card] .deck-actions {
  margin-top: 8px;
}
.deck-actions .btn {
  min-width: 36px;
}
</style>
