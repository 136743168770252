<template>
  <div class="deck-list">
    <div ref="swiper" class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="deck in decks" :key="deck.id">
          <DeckItem :deck="deck" :use-preview="usePreview" :hide-card="hideCard" :hide-actions="hideActions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
// eslint-disable-next-line import/extensions
import 'swiper/css';
import DeckItem from './DeckItem.vue';

export default {
  name: 'DeckList',
  data() {
    return {
      swiper: null,
    };
  },
  props: {
    decks: Array,
    options: Object,
    hideActions: Boolean,
    hideCard: Boolean,
    usePreview: Boolean,
  },
  components: {
    DeckItem,
  },
  watch: {
    decks() {
      this.updateSwiper();
    },
  },
  methods: {
    initSwiper() {
      this.$nextTick(() => {
        this.swiper = new Swiper(this.$refs.swiper, this.options);
      });
    },
    updateSwiper() {
      this.$nextTick(() => {
        this.swiper?.update();
      });
    },
  },
  mounted() {
    this.initSwiper();
  },
};
</script>
