<template>
  <div id="app" v-if="lang && user" :side-overlay="$route.name === 'game' || mobileLayout" :menu-open="menuOpen">
    <RampManager v-if="showAds" pub-id="1025362" website-id="75168" />
    <div class="side-panel">
      <div class="side-panel-header">
        <router-link to="/">
          <img src="/assets/logo.svg" alt="Bad Cards" class="logo">
        </router-link>
        <button class="menu-btn" @click.stop="menuOpen = false">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>
      <div class="side-panel-section game-section">
        <div class="side-panel-section-label">🕹️ {{ t('Let\'s Play!', lang) }}</div>
        <button class="btn" @click="openGameForm" :disabled="loading">{{ t('Create a game', lang) }}</button>
        <form class="input-container" @submit.prevent="joinGame">
          <input v-model="gameCode" autocapitalize="characters" type="text" placeholder="Enter your code" maxlength="7" class="input code-input" :disabled="loading">
          <button type="submit" :disabled="loading || gameCode.length !== 7">
            <span class="material-symbols-outlined">send</span>
          </button>
        </form>
      </div>
      <div class="side-panel-section">
        <button v-if="!user.subscribed" class="btn btn-box highlight" @click="openSubscription">
          <div class="button-title">{{ t('Subscribe now', lang) }} 🤘</div>
          <div>{{ t('Get access to over', lang) }} <b>{{ t('15,000 premium cards', lang) }}</b> {{ t('and remove all ads!', lang) }}</div>
        </button>
      </div>
      <RecentGames :user="user" :games="games" />
      <div class="side-panel-section menu-section">
        <div class="side-panel-section-label">📍 Menu</div>
        <router-link class="nav-link" to="/">🏠 Home</router-link>
        <router-link class="nav-link" to="/games">🕹️ Public Games</router-link>
        <router-link class="nav-link" to="/shop">🛍️ {{ t('Premium Content', lang) }}</router-link>
        <router-link class="nav-link" to="/packs">🔎 Browse Packs</router-link>
        <router-link class="nav-link" to="/packs/favorite">❤️ Favorite Packs</router-link>
        <router-link class="nav-link" to="/packs/custom">🎴 My Custom Packs</router-link>
        <router-link class="nav-link" to="/referral-program">💰 Referral Program</router-link>
      </div>
      <div class="side-panel-section">
        <div class="side-panel-section-label">🎮 {{ t('Quick Play', lang) }}</div>
        <div
          v-for="mode in quickPlayModes"
          :key="mode.id"
          class="btn btn-box game-btn secondary"
        >
          <div class="button-title">
            {{ mode.icon }} {{ mode.title }}
          </div>
          <div v-if="showGameMode === mode.id">{{ mode.description }}</div>
          <div class="button-actions">
            <button :class="`btn small${showGameMode === mode.id ? '' : ' ghost'} info-btn`" @click="showGameMode = showGameMode === mode.id ? null : mode.id">ℹ</button>
            <router-link class="btn plain small" :to="`/games/${mode.id}`" :disabled="loading">{{ t('Browse', lang) }}</router-link>
            <button class="btn small" @click="createGame(mode.id)" :disabled="loading">{{ t('Play', lang) }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-container" @click="menuOpen = false">
      <header>
        <div class="header-container">
          <button class="menu-btn" @click.stop="menuOpen = true">
            <span class="material-symbols-outlined">menu</span>
          </button>
          <router-link to="/" class="logo-header">
            <img src="/assets/logo.svg" alt="Bad Cards" class="logo">
          </router-link>
          <button v-if="!user.subscribed" class="btn highlight small" @click="openSubscription">👀 {{ t('Want more packs?', lang) }}</button>
        </div>
        <div class="header-actions" v-if="user.type === 'anonymous'">
          <button class="btn small" @click="openAuth">
            <span class="material-symbols-outlined">person</span>
            {{ t('Sign in', lang) }}
          </button>
        </div>
        <div class="header-actions" v-else>
          <router-link class="btn small" to="/packs/custom">{{ t('My Custom Packs', lang) }}</router-link>
          <!-- <button v-if="totalCustomDecks < maxCustomDecks" class="btn small deck-btn" @click="createDeck" :disabled="loading">{{ t('Create a pack', lang) }}</button> -->
          <!-- <DropdownButton :items="languageItems" width="100%" :selected="lang" @change="selectLang($event.value)">
            {{ lang }}
          </DropdownButton> -->
          <router-link class="btn small ghost" to="/account">
            <img v-if="user.avatar" :src="user.avatar" :alt="user.name" class="icon round">
            <span v-else class="material-symbols-outlined">person</span>
            {{ subcriptionIcons[user.subscription_type] || '' }}
            {{ user.name }}
          </router-link>
        </div>
      </header>
      <div v-if="showAds && $route.name !== 'game'" id="banner-pol"></div>
      <router-view/>
    </div>
    <div class="overlay" :active="overlayOpen" @click="closeOverlay">
      <div class="modal import-modal" v-if="importOpen" @click.stop>
        <div class="modal-header">
          <div class="modal-title">{{ t('🃏 Import your packs', lang) }}</div>
          <a @keypress.space="closeOverlay" @click="closeOverlay">
            <span class="material-symbols-outlined">close</span>
          </a>
        </div>
        <div class="modal-content center">
          <p>{{ t('You probably noticed, but we revamped our experience! It seems that you made some custom packs on the old platform.', lang) }}</p>
          <p>{{ t('Would you like to import them?', lang) }}</p>
        </div>
        <div class="actions">
          <button class="btn secondary" :disabled="loading" @click="importLegacyPacks(false)">{{ t('No', lang) }}</button>
          <button class="btn" :disabled="loading" @click="importLegacyPacks(true)">{{ t('Yes', lang) }}</button>
        </div>
      </div>
      <div class="modal" v-else-if="purchasedDeck" @click.stop>
        <div class="modal-header">
          <div class="modal-title">{{ t('✅ Pack Acquired', lang) }}</div>
          <a @keypress.space="closeOverlay" @click="closeOverlay">
            <span class="material-symbols-outlined">close</span>
          </a>
        </div>
        <div class="modal-content center">
          <p>{{ t('You have successfully purchased a new pack!', lang) }}</p>
          <div class="deck-container">
            <DeckItem :deck="purchasedDeck" />
          </div>
        </div>
      </div>
      <div class="modal subscription-modal" v-else-if="subscribeForm" @click.stop>
        <div class="modal-header">
          <div class="modal-title">{{ t('🤘 Subscribe', lang) }}</div>
          <a @keypress.space="closeOverlay" @click="closeOverlay">
            <span class="material-symbols-outlined">close</span>
          </a>
        </div>
        <div class="modal-content center">
          <div>
            <p>{{ t('Choose one of our plans to have access to more games, unlimited packs, and over 15,000 premium cards!', lang) }}</p>
            <div class="plan-duration">
              <div class="plan-label" :style="{ opacity: subscribeForm.yearly ? 0.5 : 1 }">{{ t('Monthly plans', lang) }}</div>
              <ToggleButton v-model="subscribeForm.yearly" :disabled="loading || !subscribeForm.canEdit" />
              <div class="plan-label" :style="{ opacity: subscribeForm.yearly ? 1 : 0.5 }">{{ t('Yearly plans', lang) }}</div>
            </div>
            <div class="plan-list">
              <button
                v-for="plan in subscriptionPlans"
                :class="`btn btn-box plan ${plan.id}`"
                :selected="subscribeForm.plan.id === plan.id"
                :key="plan.id"
                :disabled="user.subscription_type === plan.id || loading"
                @click="choosePlan(plan)"
              >
                <div class="button-title">{{ t(plan.name, lang) }}</div>
                <div class="plan-price">${{ subscribeForm.yearly ? plan.price_year : plan.price_month }}/{{ t(subscribeForm.yearly ? 'year' : 'month', lang) }}</div>
                <div>
                  {{ t('Includes:', lang) }}
                  <ul>
                    <li v-for="perk in plan.perks" :key="perk">{{ t(perk, lang) }}</li>
                  </ul>
                </div>
              </button>
            </div>
          </div>
          <div class="subscription-compare" v-if="subscribeForm.compare?.switch_total">
            {{ t('Switching to', lang) }}
            <b>{{ subscribeForm.plan.name }}</b>
            {{ t('will result in a', lang) }}
            {{ t(subscribeForm.compare.switch_total < 0 ? 'refund of' : 'extra fee of') }}
            <b>{{ currencies[subscribeForm.compare.currency] + (Math.abs(subscribeForm.compare.switch_total) / 100).toFixed(2) }}</b>.
            <br>
            {{ t('At the end of the current billing period, you will be billed', lang) }}
            <b>${{ subscribeForm.yearly ? subscribeForm.plan.price_year : subscribeForm.plan.price_month }}/{{ t(subscribeForm.yearly ? 'year' : 'month', lang) }}</b>.
          </div>
          <button v-if="user.subscribed" class="btn" :disabled="loading || subscribeForm.plan.id === user.subscription_type" @click="switchSubscription">{{ t('Update my plan', lang) }}</button>
          <StripeForm v-else :button-text="`Subscribe ${subscribeForm.yearly ? `$${subscribeForm.plan.price_year}/year` : `$${subscribeForm.plan.price_month}/month`}`" :disabled="loading" @payment-method="subscribe" />
          <div class="tos">{{ t('By confirming your subscription, you allow Bad Cards to charge you for future payments in accordance with their terms. You can always cancel your subscription. By clicking Subscribe, you agree to our Terms of Service and Privacy Policy, and you also agree to the Link Terms and Privacy Policy.', lang) }}</div>
        </div>
      </div>
      <div class="modal" v-else-if="feedbackForm" @click.stop>
        <div class="modal-header">
          <div class="modal-title">{{ t('Feedback', lang) }}</div>
          <a @keypress.space="closeOverlay" @click="closeOverlay">
            <span class="material-symbols-outlined">close</span>
          </a>
        </div>
        <div class="modal-content center" v-if="feedbackSuccess">
          <h2>{{ t('Thank you!', lang) }} ❤️</h2>
          <p>{{ t('We appreciate you taking the time to give us your feedback, it helps a lot!', lang) }}</p>
          <button class="btn ghost" @click="closeOverlay">{{ t('Close', lang) }}</button>
        </div>
        <div class="modal-content" v-else>
          <div class="form">
            <div class="field">
              <div class="field-buttons">
                <button :class="`btn small${feedbackForm.vote === 'up' ? '' : ' ghost'}`" @click="feedbackForm.vote = 'up'" :disabled="loading">
                  <span class="material-symbols-outlined">thumb_up</span>
                </button>
                <button :class="`btn small${feedbackForm.vote === 'down' ? '' : ' ghost'}`" @click="feedbackForm.vote = 'down'" :disabled="loading">
                  <span class="material-symbols-outlined">thumb_down</span>
                </button>
              </div>
            </div>
            <div class="field">
              <div class="field-label">{{ t('Share your feedback and ideas below (optional)', lang) }}</div>
              <textarea class="input" maxlength="1000" v-model="feedbackForm.message" :disabled="loading"></textarea>
            </div>
            <div class="actions">
              <button class="btn" :disabled="!feedbackForm.vote || loading" @click="sendFeedback">{{ t('Send', lang) }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AlertBox />
    <AuthModal />
    <DeckModal />
    <GameModal />
    <div class="cookie-box" v-if="allowCookies === null">
      <div class="box-title">{{ t('Cookie Consent', lang) }}</div>
      <div class="box-content">
        {{ t('We use cookies to provide you with a better experience, some are first-party cookies required for the app to work properly, and others are third-party cookies used by external tools such as Google Analytics. For more information, visit our', lang) }}
        <a href="/page/cookie-policy" target="_blank" rel="noopener noreferrer">{{ t('Cookie Policy', lang) }}</a>.
      </div>
      <div class="box-option">
        <span>{{ t('Allow third-party cookies', lang) }}</span>
        <ToggleButton v-model="cookieForm.allow" />
      </div>
      <div class="box-actions">
        <button class="btn small" @click="updateCookies">{{ t('Confirm', lang) }}</button>
      </div>
    </div>
  </div>
  <img v-else src="/assets/logo.svg" alt="Bad Cards" class="loading-logo">
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  collection, doc, getCountFromServer, getDoc, getDocs, limit, orderBy, query, where,
} from 'firebase/firestore';
import { firestore, initAnalytics, sendEvent } from './services/firebase';
import RecentGames from './components/RecentGames.vue';
import AlertBox from './components/AlertBox.vue';
import AuthModal from './components/AuthModal.vue';
import DeckItem from './components/DeckItem.vue';
import { getSearchParam } from './utils/search-params';
import StripeForm from './components/StripeForm.vue';
import { showError } from './utils/error-handling';
import DeckModal from './components/DeckModal.vue';
import ToggleButton from './components/ToggleButton.vue';
import GameModal from './components/GameModal.vue';
import RampManager from './components/RampManager.vue';

const allowCookiesValue = window.localStorage.getItem('allow-cookies');

export default {
  name: 'App',
  data() {
    return {
      overlayOpen: false,
      showAds: window.location.host === 'bad-cards-39942.web.app',
      menuOpen: false,
      feedbackForm: null,
      purchasedDeck: null,
      loading: false,
      feedbackSuccess: false,
      subscribeForm: false,
      showGameMode: null,
      importOpen: false,
      gameCode: '',
      // eslint-disable-next-line eqeqeq
      allowCookies: allowCookiesValue ? allowCookiesValue == 'true' : null,
      games: [],
      totalCustomDecks: 0,
      cookieForm: {
        allow: true,
      },
      ads: {},
      subcriptionIcons: {
        silver: '🪙',
        gold: '🏆',
        diamond: '💎',
      },
      subscriptionPlans: [
        {
          id: 'silver',
          name: '🪙 Silver plan',
          price_month: 1.99,
          price_year: 19.99,
          perks: [
            'No ads for subscribers',
            'Up to 20 custom packs',
            '15% off single-pack sales',
            'Use up to 1,500 cards in your games',
          ],
        },
        {
          id: 'gold',
          name: '🏆 Gold plan',
          price_month: 4.99,
          price_year: 46.99,
          perks: [
            'No ads for subscribers',
            'Up to 30 custom packs',
            'Access to all premium content (over 15,000 cards!)',
            'Access to all Bad Cards games',
            'Use up to 2,200 cards in your games',
          ],
        },
        {
          id: 'diamond',
          name: '💎 Diamond plan',
          price_month: 7.99,
          price_year: 74.99,
          perks: [
            '3-day trial',
            'No ads for everyone in your games',
            'Unlimited custom packs',
            'Access to all premium content (over 15,000 cards!)',
            'Access to all Bad Cards games',
            'Unlimited cards in your games',
          ],
        },
      ],
      adsLoaded: false,
      useMobileAd: window.innerWidth <= 1170,
      mobileLayout: window.innerWidth <= 1024,
    };
  },
  computed: {
    ...mapState(['user', 'client', 'lang', 'languages', 'gameModes', 'currencies']),
    languageItems() {
      return this.languages.map(({ code: value }) => ({
        label: value,
        value,
      }));
    },
    quickPlayModes() {
      let baseModes = {
        standard: 1,
        gifs: 1,
        memes: 1,
      };
      if (this.games.length) {
        baseModes = this.games.reduce((modes, game) => {
          if (!modes[game.settings.mode]) {
            modes[game.settings.mode] = 0;
          }
          modes[game.settings.mode] += 1;
          return modes;
        }, baseModes);
      }
      return [...this.gameModes].sort((a, b) => baseModes[b.id] - baseModes[a.id]).slice(0, 3);
    },
    maxCustomDecks() {
      switch (this.user?.subscription_type) {
        case 'diamond':
          return 9999;
        case 'gold':
          return 30;
        case 'silver':
          return 20;
        default:
          return 10;
      }
    },
  },
  components: {
    RecentGames,
    AlertBox,
    AuthModal,
    DeckModal,
    GameModal,
    DeckItem,
    StripeForm,
    ToggleButton,
    RampManager,
  },
  watch: {
    $route(value, previous) {
      this.menuOpen = false;
      window.scrollTo({ top: 0 });
      if (value !== previous && (value === 'game' || previous === 'game')) {
        this.loadAd();
      }
      sendEvent('pageview', { path: this.$route.fullPath, lang: this.lang });
    },
    gameCode(value, old) {
      if (value.length > old.length && value.length === 3) {
        this.gameCode += '-';
      }
    },
    user(value, old) {
      if (!old && value) {
        this.getGames();
        this.getCustomDeckCount();
        if (value.has_legacy_decks) {
          this.openImport();
        }
      }
    },
  },
  methods: {
    ...mapActions(['openAuth', 'openGame', 'openDeck', 'showMessage']),
    openGameForm() {
      this.menuOpen = false;
      if (this.user.type === 'anonymous') {
        this.openAuth();
        return;
      }
      this.openGame();
    },
    closeOverlay() {
      if (this.loading) {
        return;
      }
      this.purchasedDeck = null;
      this.subscribeForm = null;
      this.importOpen = false;
      this.overlayOpen = false;
    },
    openImport() {
      this.importOpen = true;
      this.overlayOpen = true;
    },
    openSubscription() {
      this.menuOpen = false;
      if (this.user.type === 'anonymous') {
        this.openAuth();
        return;
      }
      this.subscribeForm = {
        plan: this.user.subscription_type ? this.subscriptionPlans.find((plan) => plan.id === this.user.subscription_type) : this.subscriptionPlans[1],
        yearly: false,
        canEdit: !this.user.subscribed,
        compare: null,
      };
      this.overlayOpen = true;
      if (this.user.subscription_type) {
        this.comparePlan(this.user.subscription_type);
      }
    },
    openFeedback() {
      this.feedbackForm = {
        vote: '',
        message: '',
      };
      this.feedbackSuccess = false;
      this.menuOpen = false;
      this.overlayOpen = true;
      sendEvent('feedback-opened');
    },
    loadAd() {
      const { freestar } = window;
      if (!freestar || !this.showAds) {
        return;
      }
      if (this.adsLoaded) {
        freestar.queue.push(() => {
          freestar.deleteAdSlots();
        });
      }
      this.adsLoaded = true;
      const googleInterstitial = this.$route.name !== 'game';
      freestar.config.disabledProducts = {
        stickyFooter: true,
        stickyRail: true,
        // superflex: true,
        // sideWall: true,
        // pageGrabber: true,
        // video: true,
        // videoAdhesion: true,
        googleInterstitial,
      };
      freestar.queue.push(() => {
        freestar.newAdSlots({
          placementName: googleInterstitial ? 'badcards_header_home' : 'badcards_incontent_game',
          slotId: 'banner-pol',
        });
      });
    },
    choosePlan(plan) {
      this.subscribeForm = {
        ...this.subscribeForm,
        plan,
      };
      this.comparePlan(plan.id);
    },
    async getCustomDeckCount() {
      const result = await getCountFromServer(
        query(
          collection(firestore, 'decks'),
          where('user.id', '==', this.user.id),
          where('type', '==', 'custom'),
        ),
      );
      this.totalCustomDecks = result.data().count;
    },
    async createDeck() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const deck = await this.client.post('/decks', {
          title: 'My Custom Pack',
          language: 'EN',
          mode: 'standard',
        });
        sendEvent('create-deck', { deck_id: deck.id });
        this.openDeck({ deck, openEdit: true });
      } catch (error) {
        showError(error);
      }
      this.loading = false;
    },
    async comparePlan(plan) {
      if (!this.user.subscription_id) {
        return;
      }
      this.loading = true;
      const compare = await this.client.post(`/account/subscriptions/${this.user.subscription_id}/compare`, { plan });
      this.subscribeForm = {
        ...this.subscribeForm,
        compare,
        yearly: compare.yearly,
      };
      this.loading = false;
    },
    async createGame(mode, deck) {
      if (this.user.type === 'anonymous') {
        this.openAuth();
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const settings = {
          name: `${this.user.name || 'Unknown'}'s game`,
          mode,
          decks: deck ? [deck.id] : null,
          is_manual: true,
        };
        const game = await this.client.post('/games', settings);
        settings.id = game.id;
        sendEvent('create-game', settings);
        this.$router.push(`/g/${game.id}`);
      } catch (error) {
        showError(error);
      }
      this.loading = false;
    },
    async importLegacyPacks(approved) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const decks = await this.client.post('/decks/legacy/import-custom-decks', { approved });
        sendEvent('import-legacy-decks', { approved, deck_count: decks?.length || 0 });
        if (approved && decks?.length) {
          this.showMessage({
            type: 'success',
            message: `You have imported ${decks.length} custom pack(s)!`,
          });
        }
        this.loading = false;
        this.closeOverlay();
      } catch (error) {
        showError(error);
      }
      this.loading = false;
    },
    async joinGame() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const game = await this.client.post('/games/join', {
          code: this.gameCode.toUpperCase(),
        });
        this.$router.push(`/g/${game.id}`);
        sendEvent('join-game', { game_id: game.id });
      } catch (error) {
        showError(error);
      }
      this.gameCode = '';
      this.loading = false;
    },
    async selectLang(lang = '') {
      let strings = null;
      const language = this.languages.find((l) => l.code === lang);
      if (language && lang !== 'EN') {
        try {
          const result = await fetch(`/locales/${lang.toLowerCase()}.json?t=${Date.now()}`);
          if (result.ok) {
            strings = await result.json();
          } else {
            throw new Error('Cannot fetch language file');
          }
        } catch (error) {
          console.warn(error.message);
        }
      }
      const newLang = strings ? lang : 'EN';
      if (this.lang && newLang !== this.lang) {
        sendEvent('language-changed', { lang: newLang });
      }
      this.$store.commit('setStrings', strings);
      this.$store.commit('setLang', newLang);
    },
    async sendFeedback() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        await this.client.post('/feedback', this.feedbackForm);
        this.feedbackSuccess = true;
        sendEvent('feedback-sent', { vote: this.feedbackForm.vote });
        window.localStorage.setItem('feedback-sent', 1);
      } catch (error) {
        showError(error);
      }
      this.loading = false;
    },
    async subscribe({ paymentMethod }) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const user = await this.client.post('/account/subscriptions', {
          payment_method: paymentMethod,
          plan: this.subscribeForm.plan.id,
          yearly: this.subscribeForm.yearly,
        });
        sendEvent('subscribe', { plan: this.subscribeForm.plan.id });
        this.$store.commit('setUser', user);
        this.loading = false;
        this.closeOverlay();
        this.showMessage({
          type: 'success',
          message: 'Thank you for subscribing, premium packs are unlocked and ads removed! 🙏',
        });
        window.dispatchEvent(new CustomEvent('subscription-updated'));
      } catch (error) {
        showError(error);
        this.loading = false;
      }
    },
    async switchSubscription() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const user = await this.client.post(`/account/subscriptions/${this.user.subscription_id}`, {
          plan: this.subscribeForm.plan.id,
          proration_date: this.subscribeForm.compare.proration_date,
        });
        sendEvent('switch-subscription', { plan: this.subscribeForm.plan.id });
        this.$store.commit('setUser', user);
        this.loading = false;
        this.closeOverlay();
        this.showMessage({
          type: 'success',
          message: 'You have successfully updated your subscription!',
        });
        window.dispatchEvent(new CustomEvent('subscription-updated'));
      } catch (error) {
        showError(error);
        this.loading = false;
      }
    },
    updateCookies() {
      window.localStorage.setItem('allow-cookies', this.cookieForm.allow);
      if (typeof this.allowCookies === 'boolean' && this.allowCookies !== this.cookieForm.allow) {
        window.location.reload();
      } else {
        this.allowCookies = this.cookieForm.allow;
        if (this.allowCookies) {
          initAnalytics(this.user.id);
        }
      }
    },
    consentCookies(allow) {
      this.cookieForm.allow = allow;
      this.updateCookies();
    },
    async getPurchasedDeck() {
      const deckId = getSearchParam('purchased_item', true);
      if (deckId) {
        const deckQuery = await getDoc(doc(firestore, `decks/${deckId}`));
        if (deckQuery.exists()) {
          const deck = deckQuery.data();
          const favoriteQuery = await getDocs(query(
            collection(firestore, 'favorites'),
            where('user_id', '==', this.user.id),
            where('deck_id', '==', deck.id),
            limit(1),
          ));
          deck.favorite = !favoriteQuery.empty;
          this.purchasedDeck = deck;
          this.overlayOpen = true;
        }
      }
    },
    async getDeckWithCode() {
      const deckId = getSearchParam('pack_code', true);
      if (deckId) {
        const deckQuery = await getDoc(doc(firestore, `decks/${deckId}`));
        if (deckQuery.exists()) {
          const deck = deckQuery.data();
          const favoriteQuery = await getDocs(query(
            collection(firestore, 'favorites'),
            where('user_id', '==', this.user.id),
            where('deck_id', '==', deck.id),
            limit(1),
          ));
          deck.favorite = !favoriteQuery.empty;
          this.openDeck({ deck });
          sendEvent('open-deck-with-code', { deck_id: deck.id });
        }
      }
    },
    async getGames() {
      const gamesQuery = query(
        collection(firestore, 'games'),
        where('ended_at', '==', null),
        where('order', 'array-contains', this.user.id),
        orderBy('created_at', 'desc'),
        limit(10),
      );
      const { docs } = await getDocs(gamesQuery);
      this.games = docs.map((game) => game.data());
    },
  },
  mounted() {
    this.selectLang(this.$route.query.lang?.toUpperCase() || window.localStorage.getItem('lang') || 'EN');
    this.getPurchasedDeck();
    this.getDeckWithCode();
    this.loadAd();
    window.addEventListener('open-feedback', this.openFeedback);
    window.addEventListener('open-subscription', this.openSubscription);
    window.addEventListener('resize', () => {
      this.mobileLayout = window.innerWidth <= 1024;
    });
  },
};
</script>

<style>
@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  50% {
    -webkit-transform: scale3d(1.05,1.05,1.05);
    transform: scale3d(1.05,1.05,1.05)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #1c1a3e;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button {
  font: inherit;
  cursor: pointer;
  color: #fff;
  background: none;
  border: none;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  font: inherit;
  font-size: 16px;
  font-weight: 500;
  background: rgb(90,76,212);
  background: linear-gradient(142deg, rgba(90,76,212,1) 0%, rgba(72,60,200,1) 100%);
  border: 1px solid #7162f4;
  color: #fff;
  padding: 0 16px;
  height: 48px;
  min-width: 120px;
  white-space: nowrap;
  cursor: pointer;
}
.btn:hover {
  border-color: transparent;
}
.btn.icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  min-width: 0;
  padding: 0;
}
.btn.icon-btn {
  padding: 2px 4px;
  min-width: 0;
}
.btn .icon {
  display: block;
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.btn .icon.round {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}
.btn.secondary {
  background: rgb(43,32,99);
  background: linear-gradient(142deg, rgba(43,32,99,1) 0%, rgba(60,51,115,1) 100%);
  border-color: #363a7f;
}
.btn.secondary:hover {
  background: rgba(90,76,212,1);
  background: linear-gradient(142deg, rgba(90,76,212,1) 0%, rgba(60,51,115,1) 100%);
}
.btn.highlight,
.btn.gold {
  background: rgba(253,186,53,1);
  background: linear-gradient(142deg, rgba(253,186,53,1) 0%, rgb(222, 148, 1) 50%);
  border-color: rgb(222, 148, 1);
}
.btn.highlight:hover,
.btn.gold:hover {
  background: rgba(253,186,53,1);
}
.btn.silver {
  background: rgb(174, 170, 162);
  background: linear-gradient(142deg, rgb(174, 170, 162) 0%, rgb(196, 196, 196) 50%);
  border-color: rgb(174, 170, 162);
}
.btn.silver:hover {
  background: rgb(174, 170, 162);
}
.btn.diamond {
  background: rgb(20, 171, 247);
  background: linear-gradient(142deg, rgb(20, 171, 247) 0%, rgb(4, 137, 204) 50%);
  border-color: rgb(4, 137, 204);
}
.btn.diamond:hover {
  background: rgb(4, 137, 204);
}
.btn.ghost {
  background: rgba(94, 81, 215, 0.48);
  border-color: transparent;
}
.btn.plain {
  background: rgba(255, 255, 255, 0.08);
  color: #eee;
  border-color: transparent;
}
.btn.link {
  background: none;
  border: none;
  box-shadow: none;
  color: #eee;
}
.btn.ghost:hover,
.btn.plain:hover {
  color: #fff;
}
.btn.link:hover {
  color: #fff;
}
.btn.twitch {
  background: #9146FF;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.4);
}
.btn.twitch:hover {
  border-color: transparent;
}
.btn.patreon {
  background: #fa6753;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.4);
}
.btn.patreon:hover {
  border-color: transparent;
}
.btn.discord {
  background: #5865f2;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.4);
}
.btn.discord:hover {
  border-color: transparent;
}
.btn.discord .icon.icon-logo {
  width: auto;
  height: 24px;
}
.btn.google {
  background: #fff;
  border-color: #fff;
  color: #1c1a3e;
}
.btn.facebook {
  background: #1877F2;
  border-color: #1877F2;
}
button[disabled],
.btn[disabled] {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
.btn.small {
  height: 32px;
  font-size: 14px;
  padding: 0 8px;
  min-width: 0;
}
.btn.icon.small {
  width: 40px;
  height: 40px;
  padding: 0;
}
.btn.btn-box {
  flex-flow: column;
  height: auto;
  align-items: flex-start;
  white-space: normal;
  text-align: left;
  padding: 4px 16px 12px;
  font-size: 14px;
}
.btn.btn-box .button-title {
  font-size: 16px;
  font-weight: 600;
}
.btn.btn-box .button-actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}
.btn.btn-box .btn {
  width: auto;
  margin-bottom: 0;
}

.loading-logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 300px;
  animation-duration: 0.7s;
  animation-name: pulse;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.input {
  padding: 0 12px;
  height: 48px;
  background: #2f2a62;
  border: 1px solid #3a365d;
  border-radius: 8px;
  font: inherit;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}
.input.code-input {
  text-transform: uppercase;
}
.input.code-input::placeholder {
  text-transform: none;
}
textarea.input {
  width: 100%;
  height: 120px;
  resize: none;
  padding: 8px 12px;
}
.input[disabled] {
  color: #aaa;
}
.input::placeholder {
  color: #fff;
  opacity: 0.5;
}

.wrapper {
  width: 1200px;
  max-width: 100%;
  margin: auto;
}

* {
  box-sizing: border-box;
  scrollbar-color: #5a4cd4 #3c3373;
}

#app {
  font-family: 'Lexend', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  min-height: 100vh;
  user-select: none;
  padding-left: 300px;
}
#app[side-overlay] {
  padding-left: 0;
}

.side-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  overflow: auto;
  padding: 16px 24px;
  background: #24224a;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.23);
}
.side-panel .side-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.side-panel .logo {
  display: block;
  height: 40px;
}
.side-panel .input-container {
  position: relative;
}
.side-panel .input-container .input {
  margin: 0;
  width: 100%;
}
.side-panel .input-container button {
  position: absolute;
  top: 10px;
  right: 8px;
}
.side-panel .side-panel-section {
  margin-top: 24px;
}
.side-panel .side-panel-section.menu-section {
  background: rgba(94, 81, 215, 0.48);
  padding: 12px 0;
  border-radius: 8px;
}
.side-panel .side-panel-section-label {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 500;
}
.side-panel .side-panel-section.menu-section .side-panel-section-label {
  padding: 0 16px 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}
.side-panel .side-panel-section:not(.game-section) .btn:not(.small) {
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  padding-top: 8px;
  margin-bottom: 8px;
}
.side-panel .game-section .btn {
  width: 100%;
  margin-bottom: 12px;
}
.side-panel .btn.game-btn {
  position: relative;
  cursor: default;
  gap: 12px;
}
.side-panel .btn.game-btn .button-actions {
  justify-content: flex-start;
}
.side-panel .btn.game-btn:hover {
  background: linear-gradient(142deg, rgba(43,32,99,1) 0%, rgba(60,51,115,1) 100%);
}
.side-panel .btn.game-btn .info-btn {
  width: 32px;
}
.side-panel .nav-link {
  display: block;
  padding: 4px 0;
}
.side-panel .side-panel-section.menu-section .nav-link {
  padding: 6px 16px;
}
.side-panel .nav-link.router-link-exact-active {
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.23);
}
[side-overlay] .side-panel {
  transform: translateX(-100%);
  transition: transform 0.2s ease-out;
  z-index: 102;
}
[side-overlay][menu-open] .side-panel {
  transform: none;
}
[side-overlay][menu-open] .main-container::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.72);
  z-index: 11;
}
[side-overlay] header .menu-btn,
[side-overlay] .side-panel .menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
[side-overlay] header .logo-header,
[side-overlay] header .logo {
  display: block;
}
[side-overlay] header .logo {
  width: 100px;
}

.tags {
  display: flex;
  gap: 4px 8px;
  flex-wrap: wrap;
}
.tags .tag {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 12px;
  background: #5f51d7;
  padding: 2px 6px;
  border-radius: 12px;
  text-transform: capitalize;
}
.tags .tag .material-symbols-outlined {
  font-size: 14px;
}

h1, h2 {
  margin: 0;
}

a {
  cursor: pointer;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 16px 24px;
  background: #24224a;
  box-shadow: 8px 0 16px rgba(0, 0, 0, 0.23);
}
header .header-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}
header .menu-btn,
header .logo-header,
.side-panel .menu-btn {
  display: none;
}
header .header-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

#banner-pol {
  width: 970px;
  height: 90px;
  margin: auto;
  max-width: 100%;
}

.cookie-box {
  position: fixed;
  right: 16px;
  bottom: 16px;
  width: 500px;
  padding: 24px;
  border-radius: 16px;
  background-color: #262651;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  z-index: 3;
}
.cookie-box .box-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;
}
.cookie-box .box-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.48);
  border-radius: 8px;
  padding: 12px 16px;
  margin: 16px 0;
}
.cookie-box .box-actions {
  text-align: right;
}
.cookie-box a {
  color: #e53d13;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px;
  background: rgba(0, 0, 0, 0.72);
  visibility: hidden;
  z-index: 100;
  overflow: auto;
}
.overlay[active] {
  visibility: visible;
}

.modal {
  width: 700px;
  max-width: 100%;
  background-color: #262651;
  border-radius: 8px;
  padding: 32px 48px;
}
.modal.subscription-modal {
  width: 1024px;
}
.modal .modal-header {
  position: relative;
}
.modal .modal-header a {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.48);
}
.modal .modal-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 24px;
  padding: 12px 48px;
}
.modal .modal-content.center {
  text-align: center;
}
.modal .modal-content.center p {
  font-size: 21px;
  margin: 48px 0;
  line-height: 1.5;
}
.modal .deck-container {
  width: 240px;
  margin: auto;
}
.modal .plan-duration {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 600;
}
.modal .plan-list {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;
  margin: 24px 0;
}
.modal .plan-list .plan {
  width: calc(33.333333% - 8px);
  opacity: 0.5;
  padding: 12px;
}
.modal .plan-list .plan[selected] {
  opacity: 1;
}
.modal .plan-list .plan .button-title {
  font-size: 18px;
}
.modal .plan-list .plan .plan-price {
  font-size: 16px;
  font-weight: 500;
}
.modal .plan-list .plan ul {
  padding-left: 16px;
  margin: 0;
}
.modal .subscription-compare {
  margin-bottom: 24px;
}
.modal.import-modal .actions {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
}
.modal .tos {
  text-align: center;
  opacity: 0.75;
  margin-top: 12px;
  font-size: 12px;
}

.form .input {
  margin: 0;
}
.form .field {
  margin-bottom: 24px;
}
.form .field.inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
}
.form .field-label {
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: bold;
}
.form .field.inline .field-label {
  flex-grow: 1;
}
.form .field-label i {
  display: block;
  font-size: 14px;
  font-weight: normal;
  margin-top: 4px;
}
.form .field-note {
  font-size: 14px;
  margin-top: 4px;
}
.form .field-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.form .field-group .btn {
  min-width: 48px;
  height: 48px;
}
.form .field-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}
.form .actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 32px;
}
.form .actions.spaced {
  justify-content: space-between;
}
a.link,
.form .field-note a,
.form .actions a {
  color: #9387fb;
}
.form .field-note a:hover,
.form .actions a:hover {
  text-decoration: underline;
}
.form .input-slider {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 220px;
  height: 24px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.48);
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #5F51D7;
  cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #5F51D7;
  cursor: pointer;
}

.loader {
  display: block;
  width: 50px;
  margin: 0 auto;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side,#5F51D7 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#5F51D7);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: spin 1s infinite linear;
}

@keyframes spin {
  100% { transform: rotate(1turn) }
}

@media screen and (max-width: 1024px) {
  #app {
    padding-top: 64px;
  }
  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  header .btn.highlight {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .modal .plan-list .plan {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .overlay {
    padding: 24px;
  }
  .modal {
    padding: 24px;
  }
  .btn {
    font-size: 16px;
    padding: 10px 12px;
  }
}

@media screen and (max-width: 540px) {
  header {
    padding: 16px;
  }
  header .logo {
    height: 32px;
    margin-right: 16px;
  }
  header .header-actions .deck-btn {
    display: none;
  }
  header .btn.small {
    font-size: 12px;
    padding: 6px 8px;
  }
  header .btn.small .material-symbols-outlined {
    font-size: 16px;
  }
  .modal .field .input {
    width: 100%;
  }
  .modal .form .actions.spaced {
    flex-flow: column-reverse;
    gap: 32px;
    align-items: center;
  }
  .modal .form .field-buttons {
    justify-content: center;
  }
  .cookie-box {
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: auto;
  }
  .overlay {
    align-items: flex-start;
  }
}
</style>
