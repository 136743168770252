<template>
  <div class="alert-box-container" :opened="opened">
    <div class="alert-box" v-if="content">
      <div class="alert-box-header">
        <div class="alert-box-title" v-if="content.type === 'error'">⚠️ Error</div>
        <div class="alert-box-title" v-else-if="content.type === 'success'">✅ Success</div>
        <div class="alert-box-title" v-else>ℹ️ Info</div>
        <a @keypress.space="close" @click="close">
          <span class="material-symbols-outlined">close</span>
        </a>
      </div>
      <div class="alert-box-content">{{ content.message }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertBox',
  data() {
    return {
      opened: false,
      content: null,
    };
  },
  methods: {
    open(e) {
      clearTimeout(this.autoCloseTO);
      if (this.opened) {
        this.close();
        setTimeout(() => {
          this.open(e);
        }, 200);
        return;
      }
      this.content = e.detail;
      setTimeout(() => {
        this.opened = true;
        this.autoCloseTO = setTimeout(this.close, 5000);
      }, 200);
    },
    close() {
      clearTimeout(this.autoCloseTO);
      this.opened = false;
    },
  },
  mounted() {
    window.addEventListener('open-alert', this.open);
  },
  beforeDestroy() {
    window.removeEventListener('open-alert', this.open);
  },
};
</script>

<style scoped>
.alert-box-container {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 16px;
  overflow: hidden;
  z-index: 101;
}

.alert-box {
  width: 320px;
  transform: translateY(calc(100% + 16px));
  transition: transform 0.2s ease-out;
  background: rgb(43,32,99);
  background: linear-gradient(142deg, rgba(43,32,99,1) 0%, rgba(60,51,115,1) 100%);
  border: 1px solid #363a7f;
  border-radius: 8px;
}

.alert-box-container[opened] .alert-box {
  transform: none;
}

.alert-box-header {
  display: flex;
  align-items: center;
  padding: 4px 16px;
  border-bottom: 1px solid #363a7f;
}

.alert-box-title {
  flex-grow: 1;
  font-size: 18px;
}

.alert-box-content {
  padding: 8px 16px;
}
</style>
