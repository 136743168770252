<template>
  <div class="ramp-container"></div>
</template>

<script>
export default {
  name: 'RampManager',
  data() {
    return {
      rampLoaded: false,
    };
  },
  props: {
    pubId: {
      type: String,
      required: true,
    },
    websiteId: {
      type: String,
      required: true,
    },
  },
  watch: {
    $route() {
      if (window.ramp) {
        window.ramp.que.push(() => {
          window.ramp.spaNewPage(this.$route.path);
        });
      }
    },
  },
  methods: {
    loadAds() {
      console.log('LOAD AD', window.ramp);
      if (!window.ramp?.spaNewPage) {
        return null;
      }
      console.log('ADS LOADED');
      // const pwUnits = [
      //   {
      //     selector: '#banner-pol',
      //     type: 'med_rect',
      //   },
      // ];
      // await window.ramp.destroyUnits('all');
      // await window.ramp.spaAddAd(pwUnits);
      return window.ramp.spaNewPage(this.$route.path);
      // await window.ramp.displayUnits();
    },
  },
  mounted() {
    if (!this.pubId || !this.websiteId) {
      console.warn('[RAMP] Missing pubId or websiteId');
      return;
    }

    if (window.ramp) {
      return;
    }

    window.ramp = window.ramp || {};
    window.ramp.que = window.ramp.que || [];
    window.ramp.passiveMode = true;

    const script = document.createElement('script');
    script.src = `https://cdn.intergient.com/${this.pubId}/${this.websiteId}/ramp.js`;
    document.body.appendChild(script);

    script.onload = () => {
      window.ramp.que.push(() => {
        window.ramp.spaNewPage(this.$route.path);
      });
    };
  },
};
</script>
