<template>
  <div class="side-panel-section" v-if="gameList.length">
    <div class="side-panel-section-label">⏳ {{ t('Return to game', lang) }}</div>
    <div class="recent-games">
      <router-link v-for="game in gameList" class="game-mode btn secondary" :key="game.id" :to="`/g/${game.id}`">
        <div class="game-name">{{ game.name }}</div>
        <div class="tags">
          <div class="tag">{{ t(game.settings.is_public ? 'Public' : 'Private', lang) }}</div>
          <div class="tag">{{ game.language }}</div>
          <div class="tag">{{ gameModeNames[game.settings.mode] || game.settings.mode }}</div>
        </div>
        <button class="btn ghost small hide-btn" @click.prevent.stop="hideGame(game.id)">
          <span class="material-symbols-outlined">close</span>
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'RecentGames',
  computed: {
    ...mapState(['lang', 'gameModeNames']),
    gameList() {
      const limit = new Date();
      limit.setHours(limit.getHours() - 2);
      return this.games.filter((g) => !this.hiddenGames.includes(g.id) && g.updated_at.toDate() >= limit);
    },
  },
  props: {
    user: Object,
    games: Array,
  },
  data() {
    return {
      hiddenGames: [],
    };
  },
  methods: {
    getHiddenGames() {
      const data = window.localStorage.getItem('hidden-games');
      if (!data) {
        this.hiddenGames = [];
        return;
      }
      try {
        this.hiddenGames = JSON.parse(data);
      } catch (error) {
        this.hiddenGames = [];
      }
    },
    hideGame(gameId) {
      this.hiddenGames = [...this.hiddenGames, gameId];
      window.localStorage.setItem('hidden-games', JSON.stringify(this.hiddenGames));
    },
  },
  mounted() {
    this.getHiddenGames();
  },
};
</script>

<style scoped>
.game-mode {
  position: relative;
  flex-direction: column;
  align-items: stretch;
  height: auto;
  padding: 8px 16px;
}
.game-mode .hide-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 24px;
  height: 24px;
  padding: 0;
}
.game-mode .hide-btn .material-symbols-outlined {
  font-size: 14px;
}
</style>
