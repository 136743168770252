<template>
  <div class="card-item-container" :hover-flip="hoverFlip">
    <div :class="`card-item ${typeClass || 'default'} ${card?.type || ''}`" :flipped="!hoverFlip && !hidden && flipped">
      <div class="card-content" v-if="!card || card.content_type === 'text'" v-html="content"></div>
      <div class="card-content image" v-else-if="card.content_type === 'image'">
        <div class="loader" v-if="!imageLoaded"></div>
        <img :src="card.content" alt="" class="card-image" draggable="false" :loaded="imageLoaded" @load="imageLoaded = true">
      </div>
      <div class="card-content card-back" :style="{ backgroundImage: cover ? `url(${cover})` : null }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardItem',
  data() {
    return {
      flipped: false,
      imageLoaded: false,
    };
  },
  props: {
    card: {
      type: Object,
    },
    hidden: {
      type: Boolean,
    },
    order: {
      type: Number,
    },
    mode: {
      type: String,
    },
    cover: {
      type: String,
    },
    hoverFlip: {
      type: Boolean,
    },
  },
  computed: {
    content() {
      if (!this.card) {
        return '????????????';
      }
      if (this.card.type === 'prompt' && this.card.blank_cursors.length) {
        const cursors = [0, ...this.card.blank_cursors];
        return cursors.map((blank, index) => {
          const next = cursors[index + 1];
          const div = document.createElement('div');
          div.innerHTML = this.card.content.substring(blank ? blank + 1 : 0, next);
          return div.textContent;
        }).join('<span class="separator"></span>');
      }
      return this.card.content;
    },
    typeClass() {
      switch (this.mode) {
        case '3words':
          return 'three-words';
        default:
          return this.mode;
      }
    },
  },
  mounted() {
    if (!this.hoverFlip) {
      setTimeout(() => {
        this.flipped = true;
      }, (this.order || 0) + 1 * 250);
    }
  },
};
</script>

<style scoped>
.card-item-container {
  position: relative;
  padding-top: 140%;
  perspective: 1000px;
  overflow: hidden;
}

.card-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  color: #000;
  transition: transform 0.3s ease-out;
  transform-style: preserve-3d;
}

.card-item >>> .separator {
  display: inline-block;
  border-bottom: 1px solid;
  width: 90px;
}

.card-item .card-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: bold;
  padding: 24px 16px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: url('@/assets/bad-cards-response.png') no-repeat center/cover;
  border-radius: 16px;
  border: 4px solid #000;
}
.card-item.lyrics .card-content {
  background-image: url('@/assets/bad-lyrics-response.png');
}
.card-item.questions .card-content {
  background-image: url('@/assets/bad-questions-response.png');
}
.card-item.texts .card-content {
  background-image: url('@/assets/bad-texts-response.png');
}
.card-item.bubbles .card-content {
  background-image: url('@/assets/bad-bubbles-response.png');
}
.card-item.memes .card-content {
  background-image: url('@/assets/bad-memes-response.png');
}
.card-item.gifs .card-content {
  background-image: url('@/assets/bad-gifs-response.png');
}
.card-item.girls .card-content {
  background-image: url('@/assets/bad-girls-response.png');
}
.card-item.kids .card-content {
  background-image: url('@/assets/bad-kids-response.png');
}
.card-item.blanks .card-content {
  background-image: url('@/assets/bad-blanks-response.png');
}
.card-item.downtown .card-content {
  background-image: url('@/assets/downtown-dictionary-response.png');
}
.card-item.three-words .card-content {
  background-image: url('@/assets/3words-response.png');
}
.card-item.free-form .card-content {
  background-image: url('@/assets/dafuq-response.png');
}
.card-item.custom .card-content {
  background-image: url('@/assets/custom-cards-response.png');
}

.card-item.prompt .card-content {
  background-image: url('@/assets/bad-cards-prompt.png');
  color: #fff;
}
.card-item.lyrics.prompt .card-content {
  background-image: url('@/assets/bad-lyrics-prompt.png');
}
.card-item.questions.prompt .card-content {
  background-image: url('@/assets/bad-questions-prompt.png');
}
.card-item.texts.prompt .card-content {
  background-image: url('@/assets/bad-texts-prompt.png');
}
.card-item.bubbles.prompt .card-content {
  background-image: url('@/assets/bad-bubbles-prompt.png');
}
.card-item.memes.prompt .card-content {
  background-image: url('@/assets/bad-memes-prompt.png');
}
.card-item.gifs.prompt .card-content {
  background-image: url('@/assets/bad-gifs-prompt.png');
}
.card-item.girls.prompt .card-content {
  background-image: url('@/assets/bad-girls-prompt.png');
}
.card-item.kids.prompt .card-content {
  background-image: url('@/assets/bad-kids-prompt.png');
}
.card-item.blanks.prompt .card-content {
  background-image: url('@/assets/bad-blanks-prompt.png');
}
.card-item.downtown.prompt .card-content {
  background-image: url('@/assets/downtown-dictionary-prompt.png');
}
.card-item.three-words.prompt .card-content {
  background-image: url('@/assets/3words-prompt.png');
}
.card-item.free-form.prompt .card-content {
  background-image: url('@/assets/dafuq-prompt.png');
}
.card-item.custom.prompt .card-content {
  background-image: url('@/assets/custom-cards-prompt.png');
}

.card-item .card-content.card-back {
  background-image: url('@/assets/bad-cards-backs.png');
}
.card-item.lyrics .card-content.card-back {
  background-image: url('@/assets/bad-lyrics-backs.png');
}
.card-item.questions .card-content.card-back {
  background-image: url('@/assets/bad-questions-backs.png');
}
.card-item.texts .card-content.card-back {
  background-image: url('@/assets/bad-texts-backs.png');
}
.card-item.bubbles .card-content.card-back {
  background-image: url('@/assets/bad-bubbles-backs.png');
}
.card-item.memes .card-content.card-back {
  background-image: url('@/assets/bad-memes-backs.png');
}
.card-item.gifs .card-content.card-back {
  background-image: url('@/assets/bad-gifs-backs.png');
}
.card-item.girls .card-content.card-back {
  background-image: url('@/assets/bad-girls-backs.png');
}
.card-item.kids .card-content.card-back {
  background-image: url('@/assets/bad-kids-backs.png');
}
.card-item.blanks .card-content.card-back {
  background-image: url('@/assets/bad-blanks-backs.png');
}
.card-item.downtown .card-content.card-back {
  background-image: url('@/assets/downtown-dictionary-backs.png');
}
.card-item.three-words .card-content.card-back {
  background-image: url('@/assets/3words-backs.png');
}
.card-item.free-form .card-content.card-back {
  background-image: url('@/assets/dafuq-backs.png');
}
.card-item.custom .card-content.card-back {
  background-image: url('@/assets/custom-cards-backs.png');
}

.card-item .card-content.image {
  padding: 0;
}

.card-item .card-content.card-back,
.card-item-container .card-item {
  transform: rotateY(180deg);
}

.card-item-container .card-item[flipped],
.card-item-container[hover-flip]:hover .card-item {
  transform: none;
}

.card-item .card-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  opacity: 0;
  transition: opacity 0.2s ease-in;
}
.card-item.gifs .card-image {
  object-fit: contain;
}

.card-item .card-image[loaded] {
  opacity: 1;
}

.card-item .loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>
