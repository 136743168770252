export const getSearchParam = (param, remove) => {
  const params = new URLSearchParams(window.location.search);
  const paramValue = params.get(param);

  if (paramValue && window.history.pushState && remove) {
    params.delete(param);
    const qs = params.toString();
    const url = `${window.location.origin}${window.location.pathname}${qs ? `?${qs}` : ''}`;
    window.history.pushState({ path: url }, '', url);
  }

  return paramValue;
};
